const NEW_NEXT_PUBLIC_IMAGE_CDN = 'https://cdn.pickleball.com';
const PICKLEBALLBRACKETS_IMAGE_PREFIX = 'https://cdn.pickleballbrackets.com';

export default function getCDNURL(url: string, width: number, height: number, saturation?: number, faceCrop?: boolean): string {
	const NEXT_PUBLIC_IMAGE_CDN = process.env.NEXT_PUBLIC_IMAGE_CDN;

	if (NEXT_PUBLIC_IMAGE_CDN && url && url.startsWith(NEXT_PUBLIC_IMAGE_CDN) && !url.startsWith(PICKLEBALLBRACKETS_IMAGE_PREFIX)) {
		const basePath = url.replace(NEXT_PUBLIC_IMAGE_CDN, NEW_NEXT_PUBLIC_IMAGE_CDN);
		const sizeQuery =
			width && height
				? `?width=${width}&height=${height}&optimizer=image${saturation ? `&saturation=${saturation}` : ``}${faceCrop ? `&face_crop=${faceCrop}` : ``}`
				: '';

		return `${basePath}${sizeQuery}`;
	}

	return url;
}
